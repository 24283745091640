import React, { useEffect, useRef, useState } from "react";
import adminLayout from "../../hoc/adminLayout";
import {
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Modal,
  Row,
} from "react-bootstrap";
import { BaseUrl, token } from "../../utils/Token";
import axios from "axios";
import AddRowComponent from "./AddRowComponent";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Loader from "../../common/Loader";
import { toast } from "react-toastify";

function AddProductVariant() {
  const [sellingPrice, setSellingPrice] = useState("");
  const [sellingPriceVendor, setSellingPriceVendor] = useState("");
  const [isavailable, setisavailable] = useState("");
  const [weight, setweight] = useState("");
  const [gtin, setgtin] = useState("")
  const [barcode, setbarcode] = useState("");



  const [commission_to_be_added, setcommission_to_be_added] = useState("");
  const [variantname, setvariantname] = useState("")

  const [mrp, setMrp] = useState("");
  const [product, setProduct] = useState([]);

  const [moreinfo, setMoreinfo] = useState([{ type: "", value: "" }]);
  const [handleproduct, setHandleProduct] = useState([{ type: "", value: "" }]);
  const [warranty, setWarranty] = useState([{ type: "", value: "" }]);
  const [returnpolicy, setReturnPolicy] = useState([{ type: "", value: "" }]);
  const [open, setOpen] = useState(false);
  const [length, setlength] = useState("")
  const [width, setwidth] = useState("")
  const [height, setheight] = useState("")
  const [apicalled, setApiCalled] = useState(false);
  const [specifications, setSpecifications] = useState([
    { type: "", value: "" },
  ]);
  const [dynamicheader, setDynamicheader] = useState("");
  const [dyanmicSection, setDynamicSection] = useState([
    { type: "", value: "" },
  ]);

  const [createdby, setcreatedby] = useState("")
  const [editData, setEditData] = useState([]);
  const [imagedata, setimagedata] = useState([]);

  const [productid, setProductId] = useState("");
  const [title, setTitle] = useState("");
  const [show, setShow] = useState(false);
  const [file, setfile] = useState("")
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [tax, settax] = useState("")

  const [textareaValue, setTextareaValue] = useState("");
  const [objectArray, setObjectArray] = useState([]);
  const [loading, setloading] = useState(false)
  const checkkeycode = (e) => {
    if (e.keyCode == 220) {

      const parseobject = parseToObject(textareaValue);

      setObjectArray(parseobject);
      // setTextareaValue("");
    }
  };

  // ... Your other code ...

  const [tempObject, setTempObject] = useState(null);



  const handletextareaChange = (e) => {
    setTextareaValue(e.target.value);
  };





  let price = "";
  // Declare the price variable outside the function scope.

  const parseToObject = (input) => {
    if (!input || input.trim() === "") {
      return [];
    }

    const arraySets = input.split("|");
    const result = [];

    arraySets.forEach((arraySet) => {
      const objectSets = arraySet.split(",");

      objectSets.forEach((objectSet) => {
        const [beforeColon, afterColon] = objectSet.split(":");
        const type = beforeColon ? beforeColon.trim() : "";
        const value = afterColon ? afterColon.trim() : "";

        // If the type is "price", store the value in the "price" variable
        if (type === "price") {
          price = value;
        } else if (type && value) {
          result.push({ type, value });
        }
      });
    });
    hitApiWithParsedData(result);
    // Check if the input contains "|", and if so, hit the API
    // if (input.includes("|")) {
    //
    // }

    return result;
  };



  const hitApiWithParsedData = async (variantData) => {
    // console.log("API hit with data:", variantData);
    try {
      const response = await fetch(`${BaseUrl}/product_variant`, {
        method: "POST",
        body: JSON.stringify({
          vendor: createdby,
          product: id,
          tax: tax,

          name: variantname,
          current_selling_price: price,
          gtin: gtin,
          selling_price_by_vendor: sellingPriceVendor,
          // commission_to_be_added: commission_to_be_added,
          mrp: mrp,
          // sku: sku,
          is_available: isavailable,
          // formData.append("variant_specification", textareaValue);
          // variant_specification: textareaValue.map((item) => ({
          //   type: item.type,
          //   value: item.value,
          // })),

          // variant_specification: variantData,

          variant_specification: variantSpecification.map((item) => ({
            type: item.type,
            value: item.value,
          })),
          product_details: handleproduct.map((item) => ({
            type: item.type,
            value: item.value,
          })),



          general: general.map((item) => ({
            type: item.type,
            value: item.value,
          })),
          warranty: warranty.map((item) => ({
            type: item.type,
            value: item.value,
          })),
          return_policy: returnpolicy.map((item) => ({
            type: item.type,
            value: item.value,
          })),

          specifications: specifications.map((item) => ({
            type: item.type,
            value: item.value,
          })),
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      const data = await response.json();

    } catch (err) {

      setOpen(false);
    }
  };

  // Test examples

  const handleRemove = (index) => {
    const newArray = [...objectArray];
    newArray.splice(index, 1);
    setObjectArray(newArray);
  };

  useEffect(() => {
    const parsedObjectArray = parseToObject(textareaValue);
    setObjectArray(parsedObjectArray);
  }, []);

  const location = useLocation();
  const navigate = useNavigate();

  const { id } = useParams();

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const [variantSpecification, setvariantSpecification] = useState([
    { type: "", value: "" },
  ]);

  const [general, setGeneral] = useState([{ type: "", value: "" }]);

  const addRow = (e) => {
    e.preventDefault();
    setvariantSpecification([...variantSpecification, { type: "", value: "" }]);
  };

  const AddSpecificationRow = (e) => {
    e.preventDefault();
    setSpecifications([...specifications, { type: "", value: "" }]);
  };

  const AddReturnPolicyRow = (e) => {
    e.preventDefault();
    setReturnPolicy([...returnpolicy, { type: "", value: "" }]);
  };

  const handleWarranty = (e) => {
    e.preventDefault();
    setWarranty([...warranty, { type: "", value: "" }]);
  };

  const handleProductDetail = (e) => {
    e.preventDefault();
    setHandleProduct([...handleproduct, { type: "", value: "" }]);
  };

  const moreinfoRow = (e) => {
    e.preventDefault();
    setMoreinfo([...moreinfo, { type: "", value: "" }]);
  };

  const handleChange = (index, field, value) => {
    const updatedRows = [...variantSpecification];
    updatedRows[index][field] = value;
    setvariantSpecification(updatedRows);

  };

  const handlespecificationsChange = (index, field, value) => {
    const updatedRows = [...specifications];
    updatedRows[index][field] = value;
    setSpecifications(updatedRows);
  };
  const handlereturnChange = (index, field, value) => {
    const updatedRows = [...returnpolicy];
    updatedRows[index][field] = value;
    setReturnPolicy(updatedRows);
  };

  const handleWarrantyChange = (index, field, value) => {
    const updatedRows = [...warranty];
    updatedRows[index][field] = value;
    setWarranty(updatedRows);
  };

  const generalRow = (e) => {
    e.preventDefault();
    setGeneral([...general, { type: "", value: "" }]);
  };

  const addDynamicRow = (e) => {
    e.preventDefault();
    setDynamicSection([...dyanmicSection, { type: "", value: "" }]);
  };

  const handleChangeGeneral = (index, field, value) => {
    const updatedRows = [...general];
    updatedRows[index][field] = value;
    setGeneral(updatedRows);
  };

  const handleChangemore = (index, field, value) => {
    const updatedRows = [...moreinfo];
    updatedRows[index][field] = value;
    setMoreinfo(updatedRows);
  };

  const handleProductChange = (index, field, value) => {
    const updatedRows = [...handleproduct];
    updatedRows[index][field] = value;
    setHandleProduct(updatedRows);
  };

  const handleChangeDynamic = (index, field, value) => {
    const updatedRows = [...dyanmicSection];
    updatedRows[index][field] = value;
    setDynamicSection(updatedRows);
  };

  const handleProductFetch = (e) => {
    axios.get(`${BaseUrl}/product`, { headers }).then((res) => {
      // setProductApi(res.data.data);
      setProduct(res.data.data);
      let maindata = res.data?.data.filter((item) => item._id == id)
      setcreatedby(maindata[0]?.created_by)

    });

    // onsole.log(category[3].parent_category.name);
  };

  useEffect(() => {
    handleProductFetch();
    editView();
    changetiitle();
  }, []);



  const addvariant = location.pathname == `/addVariant/${id}`;

  const editvariant = location.pathname == `/editvariant/${id}`;

  const changetiitle = () => {
    if (addvariant) {
      setTitle("Add");
    } else {
      setTitle("Update");
    }
  };

  const editView = async () => {
    if (editvariant) {
      const res = await axios.get(`${BaseUrl}/product_variant_single/${id}`, {
        headers,
      });
      setEditData(res.data.data);


      const editGetData = res.data.data;
      setProductId(res.data.data.product._id);

      setSellingPrice(editGetData.current_selling_price);
      setSellingPriceVendor(res.data.data.selling_price_by_vendor);
      setisavailable(res.data.data.is_available);
      setvariantname(editGetData?.name)
      settax(editGetData?.tax)
      setweight(editGetData?.weight)
      setimagedata(editGetData.image)
      setbarcode(editGetData?.barcode)
      setlength(editGetData?.length)
      setwidth(editGetData?.width)
      setheight(editGetData?.height)

      setgtin(editGetData?.gtin)


      setcommission_to_be_added(res.data.data.commission_to_be_added);
      // setSku(res.data.data.sku);
      setMrp(res.data.data.mrp);

      const generalData = editGetData.general.map((product) => ({
        type: product.type,
        value: product.value,
      }));
      setGeneral(generalData);

      const variantSpecificationData = editGetData.variant_specification.map(
        (product) => ({
          type: product.type,
          value: product.value,
        })
      );

      setvariantSpecification(variantSpecificationData);

      const productDetailData = editGetData.product_details.map((product) => ({
        type: product.type,
        value: product.value,
      }));
      setHandleProduct(productDetailData);

      const warrantyData = editGetData.warranty.map((product) => ({
        type: product.type,
        value: product.value,
      }));
      setWarranty(warrantyData);

      const returnPolicyData = editGetData.return_policy.map((product) => ({
        type: product.type,
        value: product.value,
      }));
      setReturnPolicy(returnPolicyData);

      const specificationData = editGetData.specifications.map((product) => ({
        type: product.type,
        value: product.value,
      }));
      setSpecifications(specificationData);
    }
  };



  const handleSubmit = async (e) => {
    e.preventDefault();
    setOpen(true);
    if (addvariant) {
      try {
        const response = await fetch(`${BaseUrl}/product_variant`, {
          method: "POST",
          body: JSON.stringify({
            vendor: createdby,
            product: id,
            name: variantname,
            tax: tax,
            current_selling_price: sellingPrice,
            selling_price_by_vendor: sellingPriceVendor,
            // commission_to_be_added: commission_to_be_added,
            mrp: mrp,
            weight: weight,
            barcode: barcode,

            is_available: isavailable,

            gtin: gtin,
            variant_specification: variantSpecification.map((item) => ({
              type: item.type,
              value: item.value,
            })),
            product_details: handleproduct.map((item) => ({
              type: item.type,
              value: item.value,
            })),
            length: length,
            width: width,
            height: height,


            general: general.map((item) => ({
              type: item.type,
              value: item.value,
            })),
            warranty: warranty.map((item) => ({
              type: item.type,
              value: item.value,
            })),
            return_policy: returnpolicy.map((item) => ({
              type: item.type,
              value: item.value,
            })),
            specifications: specifications.map((item) => ({
              type: item.type,
              value: item.value,
            })),
          }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        const data = await response.json();


        if (data.status == "OK") {
          setOpen(false);
          console.log(data)
          toast.success("data Submitted Succesfully");
          imageupload(data._id)

        } else {
          toast.error("data Not Submitted");
          setOpen(false);
        }
      } catch (err) {

        setOpen(false);
      }
    } else {
      try {
        const response = await fetch(`${BaseUrl}/product_variant`, {
          method: "PUT",
          body: JSON.stringify({
            vendor: createdby,
            _id: id,
            product: productid,
            name: variantname,
            tax: tax,
            current_selling_price: sellingPrice,
            selling_price_by_vendor: sellingPriceVendor,
            // commission_to_be_added: commission_to_be_added,
            mrp: mrp,
            weight: weight,
            barcode: barcode,
            length: length,
            width: width,
            height: height,
            // sku: sku,
            is_available: isavailable,
            gtin: gtin,

            variant_specification: objectArray,

            // variant_specification: textareaValue.map((item) => ({
            //   type: item.type,
            //   value: item.value,
            // })),
            product_details: handleproduct.map((item) => ({
              type: item.type,
              value: item.value,
            })),

            // dynamic_specification: [
            //   {
            //     header: "Connectivity",
            //     specification: [
            //       {
            //         value: "5.0",
            //         type: "Bluetooth",
            //       },
            //     ],
            //   },
            // ],

            general: general.map((item) => ({
              type: item.type,
              value: item.value,
            })),
            warranty: warranty.map((item) => ({
              type: item.type,
              value: item.value,
            })),
            return_policy: returnpolicy.map((item) => ({
              type: item.type,
              value: item.value,
            })),
            specifications: specifications.map((item) => ({
              type: item.type,
              value: item.value,
            })),

          }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        const data = await response.json();


        if (data.status == "OK") {
          setOpen(false);
          toast.success("data Updated Succesfully");
          // navigate("/viewproduct");
          imageupload(id)
        } else {
          toast.error("data Not Submitted");
          setOpen(false);
        }
      } catch (err) {
        setOpen(false);
        toast.error("data not Updated");
      }
    }
  };



  const imageupload = (maind) => {
    let formdata = new FormData()
    formdata.append("file", file)
    formdata.append("_id", maind)

    axios.put(`${BaseUrl}/addImagesToVariants`, formdata, { headers }).then((res) => {
      console.log(res)
      navigate("/viewproduct");
    })
  }

  const handleRemoveImage = (e, number) => {
    setloading(true)
    e.preventDefault()
    let requestdata = {
      _id: id,
      index: number,
    }
    axios.put(`${BaseUrl}/removeImageFromVariants`, requestdata, { headers }).then((res) => {
      console.log(res)
      if (res.data.status == "OK") {
        editView()
        setloading(false)
      }
    })
  }

  return (
    <>
      {loading && <Loader />}
      <section>
        <Container>
          <Row>
            <Col md="12">
              <div className="bg-white  addProduct">
                <h4 className="px-3 py-3">{title} Product Variant</h4>
                <div className="">
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <div className="d-flex flex-wrap">
                        {imagedata.map((item, index) => {
                          return (
                            <div key={index} style={{ position: "relative", display: "inline-block", margin: "10px" }}>
                              <img
                                src={`${BaseUrl}/${item}`}
                                style={{ width: "150px", height: "150px", objectFit: "contain", border: "1px solid #ddd", borderRadius: "5px" }}
                                className="img-fluid"
                                alt=""
                              />
                              <button
                                onClick={(e) => handleRemoveImage(e, index)}
                                style={{
                                  position: "absolute",
                                  top: "-5px",
                                  right: "-5px",
                                  background: "red",
                                  color: "white",
                                  border: "none",
                                  borderRadius: "50%",
                                  width: "25px",
                                  height: "25px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  cursor: "pointer",
                                  fontSize: "14px",
                                }}
                              >
                                &times;
                              </button>
                            </div>
                          );
                        })}
                      </div>
                    </Row>
                    <Row className="p-3 productform">
                      <Col md="6">
                        <FormGroup>
                          <label htmlFor="" className="fw-bold">
                            Image
                          </label>
                          <input
                            type="file"
                            className="form-control"

                            onChange={(e) => setfile(e.target.files[0])}
                          />
                        </FormGroup>
                      </Col>

                      <Col md="6">
                        <FormGroup>
                          <label htmlFor="" className="fw-bold">
                            {" "}
                            Name
                          </label>
                          <input
                            type="text"
                            className="form-control"

                            name="current_selling_price"
                            value={variantname}
                            onChange={(e) => setvariantname(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label htmlFor="" className="fw-bold">
                            {" "}
                            Selling Price <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            required
                            name="current_selling_price"
                            value={sellingPrice}
                            onChange={(e) => setSellingPrice(e.target.value)}
                          />
                        </FormGroup>
                      </Col>

                      <Col md="6">
                        <FormGroup>
                          <label htmlFor="" className="fw-bold">
                            {" "}
                            Selling Price Vendor{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            required
                            name="selling_price_by_vendor"
                            value={sellingPriceVendor}
                            onChange={(e) =>
                              setSellingPriceVendor(e.target.value)
                            }
                          />
                        </FormGroup>
                      </Col>

                      <Col md="6">
                        <FormGroup>
                          <label htmlFor="" className="fw-bold">
                            {" "}
                            Is Available
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="is_available"
                            value={isavailable}
                            onChange={(e) => setisavailable(e.target.value)}
                          />
                        </FormGroup>
                      </Col>

                      {/* <Col md="6">
                        <FormGroup>
                          <label htmlFor="" className="fw-bold">
                            {" "}
                            Commission to be added
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="commission_to_be_added"
                            value={commission_to_be_added}
                            onChange={(e) =>
                              setcommission_to_be_added(e.target.value)
                            }
                          />
                        </FormGroup>
                      </Col> */}


                      <Col md="6">
                        <FormGroup>
                          <label htmlFor="" className="fw-bold">
                            {" "}
                            MRP <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            required
                            name="mrp"
                            value={mrp}
                            onChange={(e) => setMrp(e.target.value)}
                          />
                        </FormGroup>
                      </Col>


                      <Col md="6">
                        <FormGroup>
                          <label htmlFor="" className="fw-bold">
                            {" "}
                            Tax <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            required
                            name="tax"
                            value={tax}
                            onChange={(e) => settax(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label htmlFor="" className="fw-bold">
                            {" "}
                            Weight <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            required
                            name="weight"
                            value={weight}
                            onChange={(e) => setweight(e.target.value)}
                          />
                        </FormGroup>
                      </Col>

                      <Col md="6">
                        <FormGroup>
                          <label htmlFor="" className="fw-bold">
                            {" "}
                            Barcode <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            required
                            name="barcode"
                            value={barcode}
                            onChange={(e) => setbarcode(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label htmlFor="" className="fw-bold">
                            {" "}
                            Length <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            required
                            name="length"
                            value={length}
                            onChange={(e) => setlength(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label htmlFor="" className="fw-bold">
                            {" "}
                            Width <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            required
                            name="width"
                            value={width}
                            onChange={(e) => setwidth(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label htmlFor="" className="fw-bold">
                            {" "}
                            Height <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            required
                            name="height"
                            value={height}
                            onChange={(e) => setheight(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label htmlFor="" className="fw-bold">
                            {" "}
                            GTIN <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            required
                            name="gtin"
                            value={gtin}
                            onChange={(e) => setgtin(e.target.value)}
                          />
                        </FormGroup>
                      </Col>

                      {/* {editvariant ? (
                        <></>
                      ) : (
                        <Col md="12">
                          <FormGroup>
                            <label htmlFor="" className="fw-bold">
                              Variation
                            </label>

                            <textarea
                              name="variant_specification"
                              id=""
                              cols="30"
                              rows="10"
                              className="form-control"
                              value={textareaValue}
                              onChange={(event) => handletextareaChange(event)}
                              onKeyDown={(event) => checkkeycode(event)}
                            ></textarea>

                            <div className="tags-container">
                              <div className="tag">
                                <div className="tag-item">{textareaValue}</div>
                              </div>
                              <div className="d-none">
                                {objectArray.map((obj, index) => (
                                  <div className="tag" key={index}>
                                    <div className="tag-item">
                                      {obj.type}: {obj.value},
                                    </div>
                                    <button
                                      className="remove-button"
                                      onClick={() => handleRemove(index)}
                                    >
                                      &#10006;
                                    </button>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </FormGroup>
                        </Col>
                      )} */}

                      <AddRowComponent
                        title="Variant Specification"
                        onClick={addRow}
                        data={variantSpecification}
                        name="variant_specification"
                        value={variantSpecification}
                        onChange={handleChange}
                      />

                      <AddRowComponent
                        title="Faq"
                        onClick={generalRow}
                        data={general}
                        name="general"
                        value={general}
                        onChange={handleChangeGeneral}
                      />

                      <AddRowComponent
                        title="Product Details"
                        onClick={handleProductDetail}
                        data={handleproduct}
                        name="product_details"
                        value={handleproduct}
                        onChange={handleProductChange}
                      />

                      <AddRowComponent
                        title="warranty"
                        onClick={handleWarranty}
                        data={warranty}
                        name="warranty"
                        value={warranty}
                        onChange={handleWarrantyChange}
                      />

                      <AddRowComponent
                        title="Return Policy"
                        onClick={AddReturnPolicyRow}
                        data={returnpolicy}
                        name="return_policy"
                        value={returnpolicy}
                        onChange={handlereturnChange}
                      />

                      <AddRowComponent
                        title="Specification"
                        onClick={AddSpecificationRow}
                        data={specifications}
                        name="specifications"
                        value={specifications}
                        onChange={handlespecificationsChange}
                      />

                      <Col md="12">
                        <div className="">
                          <button className="btn">Submit</button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <Loader open={open} />
      </section>

      <Modal show={show} size="lg" onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="text-dark">Variant</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit="handleformEditSubmit"></Form>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn bg-primary text-white" onClick={handleClose}>
            Submit
          </button>
        </Modal.Footer>

        {/* <div className="tags-container">
          {objectArray.map((obj, index) => (
            <div className="tag" key={index}>
              {Object.entries(obj).map(([key, value]) => (
                <div className="tag-item" key={key}>
                  {key}: {value}
                </div>
              ))}
              <button
                className="remove-button"
                onClick={() => handleRemove(index)}
              >
                &#10006;
              </button>
            </div>
          ))}
        </div> */}
      </Modal>
    </>
  );
}

export default adminLayout(AddProductVariant);
