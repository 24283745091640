import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { Button, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { Col, Container, Row } from "react-bootstrap";
import adminLayout from "../../hoc/adminLayout";
import { Link, useParams } from "react-router-dom";
import { BaseUrl, token } from "../../utils/Token";

// it is category List And this View Product

function ViewProductVariant() {
  const { id } = useParams();
  const [product, setProduct] = useState([]);

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(`${BaseUrl}/product_variant/${id}`, {
        headers,
      });
      setProduct(response.data.data);
    } catch (error) {
      console.error("Error fetching category data:", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const getRowId = (row) => row._id;
  const handleEditClick = (id) => {

  };

  const columns = [
    {
      field: "image",
      headerName: "Image",
      width: 300,
      renderCell: (params) => {
        return (
          <>
            <img src={`${BaseUrl}/${params.row.image[0]}`} style={{ height: "50px", width: "50px", borderRadius: "50%" }} alt="" />
          </>
        )
      }
    },
    {
      field: "current_selling_price",
      headerName: "current_selling_price",
      width: 300,
    },
    {
      field: "selling_price_by_vendor",
      headerName: "selling_price_by_vendor ",
      width: 250,
    },
    // {
    //   field: "commission_to_be_added",
    //   headerName: "commission_to_be_added",
    //   width: 250,
    // },
    // {
    //   field: "image",
    //   headerName: "image",
    //   width: 250,
    // },
    {
      field: "mrp",
      headerName: "mrp",
      width: 300,
      //   renderCell: (params) => {
      //     const { value } = params;
      //     return (
      //       <img
      //         src={`${BaseUrl}/${value}`}
      //         className="img-fluid tableimgmui"
      //       ></img>
      //     );
      //   },
    },
    {
      field: "edit",
      headerName: "Edit",
      width: 100,
      renderCell: (params) => {
        const id = params.row._id;
        return (
          <Link to={`/editvariant/${id}`}>
            <IconButton
              color="primary"
              aria-label="edit"
              onClick={() => handleEditClick(id)}
            >
              <EditIcon />
            </IconButton>
          </Link>
        );
      },
    },
  ];

  return (
    <>
      <section>
        <Container>
          <Row>
            <Col md="12">
              <div className="bg-white  box">
                <h4 className="px-3 py-3">Variant List</h4>
                <div className="">
                  {product && product.length > 0 ? (
                    <DataGrid
                      rows={product}
                      columns={columns}
                      getRowId={getRowId}
                      initialState={{
                        ...product.initialState,
                        pagination: { paginationModel: { pageSize: 10 } },
                      }}
                      pageSizeOptions={[5, 10, 25]}

                    />
                  ) : (
                    <div className="d-flex justify-content-center py-5">
                      <h3>Product variant is not Available</h3>
                    </div>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default adminLayout(ViewProductVariant);
