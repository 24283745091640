import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { Button, IconButton, Switch } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { Col, Container, Row } from "react-bootstrap";
import adminLayout from "../../hoc/adminLayout";
import { Link } from "react-router-dom";
import { BaseUrl, token } from "../../utils/Token";
import Loader from "../../common/Loader";
import { toast } from "react-toastify";
import moment from "moment/moment";

function CategoryList() {
  const [categories, setCategories] = useState([]);
  const [open, setOpen] = useState(false);
  const [checked, setChecked] = useState("")
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  console.log("toekn", token);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${BaseUrl}/category`, {
        headers,
      });
      setCategories(response.data.data);
    } catch (error) {
      console.error("Error fetching category data:", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const getRowId = (row) => row._id;
  const handleEditClick = (id) => {

  };

  const columns = [
    // { field: "_id", headerName: "ID", flex :1 },
    { field: "name", headerName: "Name", flex: 1 },
    // {
    //   field: "image",
    //   headerName: "image",
    //   width: 250,
    // },
    {
      field: "image",
      headerName: "image",
      flex: 1,
      renderCell: (params) => {
        const { value } = params;
        return (
          <img
            src={`${BaseUrl}/${value}`}
            className="img-fluid tableimgmui"
          ></img>
        );
      },
    },

    { field: "commission", headerName: "Commission", flex: 1 },
    {
      field: "flow",
      headerName: "Flow",
      flex: 1,
      renderCell: (params) => {
        const { value } = params;
        return (
          <h6>{params.row?.flow?.name}</h6>
        );
      },
    },

    {
      field: "is_active", headerName: "Is Active", flex: 1, renderCell: (params) => {
        return (
          <>
            <label>
              <Switch onChange={(e) => handleChange(params.row.is_active, params.row._id)} checked={params.row.is_active} />
            </label>
          </>
        )
      }
    },
    {
      field: "edit",
      headerName: "Edit",
      flex: 1,
      renderCell: (params) => {
        const id = params.row._id;
        return (
          <Link to={`/single_category/${id}`}>
            <IconButton
              style={{ color: "black" }}
              aria-label="edit"
              onClick={() => handleEditClick(id)}
            >
              <EditIcon />
            </IconButton>
          </Link>
        );
      },
    },

    {
      field: "delete",
      headerName: "Delete",
      flex: 1,
      renderCell: (params) => {
        const id = params.row._id;
        return (
          <Link>
            <IconButton
              color="primary"
              aria-label="edit"
              onClick={() => handledeleteClick(id)}
            >
              <i class="fa-sharp fa-solid fa-trash text-dark"></i>
            </IconButton>
          </Link>
        );
      },
    },

    {
      field: "Created At",
      headerName: "Created At",
      flex: 1,
      renderCell: (params) => {
        const { value } = params;
        return (
          <h6>{moment(params.row?.flow?.created_at).format("DD-MM-YYYY")}</h6>
        );
      },
    },

  ];


  const handleChange = (checked, id) => {
    // console.log("hellocheck", !checked)
    setChecked(!checked);
    setOpen(true)
    let requestData = {
      _id: id,
      is_active: !checked
    }
    axios.put(`${BaseUrl}/category`, requestData, { headers }).then((res) => {
      console.log(res.data)
      if (res.data.status == "OK") {
        toast.success("Succesffully Update")
        fetchData()
      }
    }).finally(() => {
      setOpen(false)
    })
  };

  const handledeleteClick = async (id) => {
    setOpen(true);
    let data = JSON.stringify({
      _id: id,
      deleted_at: "D",
    });

    let config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: `${BaseUrl}/category`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        if (response.data.status == "OK") {
          toast.success("category Deleted Succesfully");
          setOpen(false);
          window.location.reload(true);
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error("Category Not Deleted Succesfully");
        setOpen(false);
      });
  };

  return (
    <>
      <Loader open={open} />
      <section>
        <Container>
          <Row>
            <Col md="12">
              <div className="bg-white  box">
                <h4 className="px-3 py-3">Category List</h4>
                <div className="">
                  <DataGrid
                    rows={categories}
                    columns={columns}
                    getRowId={getRowId}
                    initialState={{
                      ...categories.initialState,
                      pagination: { paginationModel: { pageSize: 10 } },
                    }}
                    pageSizeOptions={[5, 10, 25]}
                  // checkboxSelection
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default adminLayout(CategoryList);

